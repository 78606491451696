import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import PeopleIcon from '@material-ui/icons/People';
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Api from '../../../../../../common/api'
import Local from '../../../../../../common/local'
import Util from '../../../../../../common/util';
import Recaptcha from '../../../../../../common/components/recaptcha';

import CurrentUser from '../../../../shared/components/current-user';
import Heading from '../../../../shared/components/heading';
import LeftRail from '../../../../shared/components/left-rail';
import PageHeader from '../../../../shared/components/page-header';
import StandardInput from '../../../../shared/components/standard-input';

const styles = (theme) => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  copyContainer: {
    padding: "0 0 0 0",
    [theme.breakpoints.up('md')]: {
      padding: "0 25px 0 0",
    },
  },
  forgotContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  },
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25px"
  },
  button: {
    padding: "15px 20px",
    margin: "0 10px 10px 10px"
  },
  continueButton: {
    width: '100%'
  },
  profileButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  orText: {
    textAlign: 'center',
    width: '100%'
  },
  ctaBlock: {
    paddingTop: '40px'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '13px',
    margin: "10px 0",
  },
  createAccount: {
    textAlign: 'right',
  },
  loginButton: {
    marginBottom: '10px'
  },
  gnLoginButton: {
    marginBottom: '10px',
    borderRadius: '4px',
    backgroundColor: '#77bc43 !important',
    '&:hover': {
      backgroundColor: '#77bc43 !important',
      color: '#FFF'
    },
  },
  noHeaderContainer: {
    padding: '0px 20px 0 20px',
    maxWidth: '1200px',
    margin: '0 auto 50px auto'
  },
  recaptchaText: {
    fontSize: '12px'
  },
  textChangeUser: {
    fontSize: '16px',
    cursor: 'pointer',
  },
});

class AuthenticationView extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      client: null,
      username: '',
      password: '',
      processing: false,
      error: this.props.loggedOut === 'true' ?
      'You have been logged out. Please re-authenticate to continue.'
      : null,
      placeholders: {
        username: 'Email Address',
        password: 'Password'
      },
      redirect: null,
      showPassword: false,
      validation: {
        username: null,
        password: null
      }
    };

    this.api = new Api();
    this.continueAsLogin = this.continueAsLogin.bind(this);
    this.forNewAccount = this.forNewAccount.bind(this);
    this.generateOnInput = this.generateOnInput.bind(this);
    this.onCreateAccount = this.onCreateAccount.bind(this);
    this.onError = this.onError.bind(this);
    this.onForgotPasswordClick = this.onForgotPasswordClick.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.onSwitchUsersClick = this.onSwitchUsersClick.bind(this);
    this.startLogin = this.startLogin.bind(this);
    this.redirect = this.redirect.bind(this);
    this.viewProfile = this.viewProfile.bind(this);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { username: null, password: null }
      });
    }.bind(this);
  }

  onError(errObj) {

    this.setState({ processing: false });
    this.props.onError(errObj);
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      return await this.startLogin();
    }
  }

  onCreateAccount() {
    this.redirect(`/new-account${Util.toQueryString(this.props.query)}`);
  }

  onForgotPasswordClick(e) {
    e.preventDefault()
    this.redirect(`/forgot${Util.toQueryString(this.props.query)}`);
  }

  onSwitchUsersClick(e) {
    e.preventDefault();
    this.props.onShowAvailableUsers();
  }

  async startLogin() {
    if (window.globals && window.globals.gidRecaptchaKey) {
      const that = this;
      window.grecaptcha.ready(function() {
        window.grecaptcha.execute(window.globals.gidRecaptchaKey, {action: 'submit'}).then(async function(token) {
          await that.onLogin(token);
        });
      });
    } else {
      await this.onLogin();
    }
  }

  async onLogin(recaptcha) {

    const {
      username,
      password
    } = this.state;

    const validation = {
      username: username === '' ? 'Please enter an email address.' : null,
      password: password === '' ? 'Please enter a password.' : null
    };

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    this.setState({ processing: true });
    const that = this;

    try {
      const response = await this.api.post(
        `/user/authenticate${this.props.clientId ? `?clientId=${this.props.clientId}` : ''}`,
        {
          username,
          password,
          recaptcha,
          parameters: Util.toCoreQueryObject(this.props.query)
        }
      );

      if (!response.code) {
        return this.onError({
          error: (
            <div>
              <div>
                We're sorry. Our servers are having trouble logging you in right now.
              </div>
              <div>
                Please try again later.
              </div>
            </div>
          ), critical: true
        });
      }

      if (response.code === 400) {
        return this.onError({
          error: function() {
            if (response.content.validation && response.content.validation.keys.length && response.content.validation.keys[0] === 'username') {
              validation.username = 'Please enter a valid email address.';
              that.setState({ validation });
              return 'This doesn\'t look like a valid email address. Please check it and try again.';
            }
            return undefined;
          }() || 'Please double-check your email address for mistakes and try again.'
        });
      }

      if (response.code === 401) {
        return this.onError({
          error: (
            <div>
              <div>We couldn't find a user with that address, or the password didn't match.</div>
              <div>Please check your credentials and try again.</div>
            </div>
          )
        });
      }
      else if (response.code === 422) {
        return this.props.onRequireResetPassword(username);
      }
      else if (response.code === 423) {
        return this.onError({
          error: (
            <div>
              <div>
                This account has been temporary locked for too many failed login attempts.
              </div>
              <div>
                Please try again in a while, or contact customer support for immediate assistance.
              </div>
            </div>
          )
        });
      }
      else if (response.code === 417) {
        return this.props.onTwoFactor(username, password);
      }
      else if (response.code === 426) {
        return this.props.onUpgradeAccount(username);
      }
      else if (response.code === 428) {
        return this.props.onRequiresReconciliation(username);
      }

      if (!response.content || !response.content.token) {
        return this.onError({
          error: (
            <div>
              <div>
                We're sorry. Our servers are having trouble logging you in right now.
              </div>
              <div>
                Please try again later.
              </div>
            </div>
          ), critical: true
        });
      }

      Local.userLogin(response.content.token, username);
      return this.props.onLoginSuccess();
    }
    catch (e) {
      return this.onError({ error: e.toString() });
    }
  }

  onShowPassword() {
    this.setState({showPassword: !this.state.showPassword});
  }

  continueAsLogin() {
    const newQuery = Object.assign(this.props.query, {});
    delete newQuery.reauthenticate;
    window.location.href = `/${Util.toQueryString(newQuery)}`;
  }

  viewProfile() {

    const newQuery = Object.assign(this.props.query, {});
    newQuery.was_reauthenticating = 'true';
    window.location.href = `/preferences${Util.toQueryString(newQuery, ['reauthenticate'])}`;
  }

  forNewAccount() {

    window.location.href = `/new-account${Util.toQueryString(this.props.query)}`;
  }

  async componentWillMount() {

    if (this.props.query.aslogin) {
      return this.setState({
        username: this.props.query.aslogin
      });
    }
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    const gridSize = (
      this.props.query.noHeader ?
      12 : 6
    );

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    const loginButtonClass = this.props.query.gnButton ?
      this.props.classes.gnLoginButton :
      this.props.classes.button;

    const createAccount = (
      this.props.query.noCreate ?
      null :
      <Button className={this.props.classes.button} color="primary" onClick={this.onCreateAccount} variant="outlined">
        Create Account
      </Button>
    );

    const forgotPassword = (
      this.props.query.noForgot ?
      null :
      <Grid className={this.props.classes.forgotContainer} container spacing={0}>
        <Link color="textSecondary" className={this.props.classes.link} onClick={this.onForgotPasswordClick} href={`/forgot${Util.toQueryString(this.props.query)}`}>
          FORGOT PASSWORD?
        </Link>
      </Grid>
    );

    const bottomControls = (
      <Grid className={this.props.classes.buttonControls} container spacing={0}>
        <Button className={loginButtonClass} color="primary" onClick={this.startLogin} variant="contained">
          {this.state.processing ?
            (<CircularProgress size={14} color="inherit" />)
            : 'Login'
          }
        </Button>
        {createAccount}
      </Grid>
    );

    const containerStyle =
      this.props.query.noHeader ?
      this.props.classes.noHeaderContainer :
      this.props.classes.view;

    const leftPane = (
      this.props.query.noHeader ?
        null :
        <Grid item xs={12} md={6} className={this.props.classes.copycontainer} >
          <LeftRail clientId={this.props.clientId} />
        </Grid>
    );

    const switchAccount =
      this.props.isReauthenticationRequest && this.props.user ?
      (
        <Grid item xs={12}>
          <PageHeader text="Switch Accounts?" />
          <Heading text="Use Current Account" />
          <CurrentUser user={this.props.user} />
          <Grid className={this.props.classes.buttonControls} container spacing={0}>
            <Button color="primary" className={this.props.classes.button} onClick={this.continueAsLogin} style={{width: '150px'}} variant="contained">
              Use This Account
            </Button>
          </Grid>
          <p className={this.props.classes.orText}>- or -</p>
          <Heading text="Login to Another Account" />
        </Grid>
      )
    :
    null;

    let selectUser =
      Local.getAvailableUsers() && Object.keys(Local.getAvailableUsers()).length > 0 ?
      (
        <Typography color="textSecondary" className={this.props.classes.textChangeUser}>
          <Link color="inherit" onClick={this.onSwitchUsersClick}>
            {<PeopleIcon style={{position: 'relative', top: '4px', width: '18px', height: '18px'}} />} Select User
          </Link>
        </Typography>
      ) : null;

    if (this.props.query.noCreate || this.props.query.noSelectUser) {
      selectUser = null;
    }

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    const recaptcha = (window.globals && window.globals.gidRecaptchaKey && !this.props.query.noForgot) ?
      <Recaptcha textClassname={this.props.classes.recaptchaText}/> : null;

    return (
      <Grid container spacing={0} className={containerStyle} >
        {leftPane}
        <Grid item xs={12} md={gridSize}>
          {switchAccount}
          <StandardInput
            inputType="email"
            value={this.state.username}
            disabled={!!this.props.query.aslogin}
            style={{ marginBottom: '32px' }}
            error={this.state.validation.username}
            attribute="username"
            label="EMAIL ADDRESS"
            focus={true}
            onChange={this.generateOnInput('username')}
            onKeyUp={this.onSubmitPressed} />
          <StandardInput
            inputType={this.state.showPassword ? 'text' : 'password'}
            style={{ marginBottom: '12px' }}
            error={this.state.validation.password}
            password={true}
            attribute="password"
            label="PASSWORD"
            endAdornment={endAdornment}
            onChange={this.generateOnInput('password')}
            onKeyUp={this.onSubmitPressed} />
          {selectUser}
          {recaptcha}
          {bottomControls}
          {forgotPassword}
        </Grid>

      </Grid>
    );
  }
}

export default withStyles(styles)(AuthenticationView);
