import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom';

import {withStyles} from '@material-ui/core';

import Loading from '../../../../base/shared/components/loading';

import {setError} from "../../../../../actions/auth";
import {setReturnTo} from '../../../../../actions/return';
import Api from "../../../../../common/api";
import IdentityStatus from "./views/identity-status"
import LeftRail from "../../../shared/components/left-rail";
import PageHeader from '../../../shared/components/page-header';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import StudentView from "./views/student/index"
import MilitaryView from "./views/military/index"

const styles = theme => ({
  pageContainer: theme.pageContainer,
  card: {
    background: theme.palette.background.light,
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
});

class Page extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirectTo: null,
      // verificationId: null,
      // programId: window.globals ? window.globals.gidStudentProgramId : '60c9e65288e17849464951fc',
      userStatus: null,
      screen: 'start',
      identities: null,
    };
    this.api = new Api();
  }

  async componentDidMount() {
    try {
      // // The 2 indicates the page was accessed by navigating into the history
      // if (performance.navigation.type === 2) {
      //   window.location.reload(true);
      //   return;
      // }
      const identitiesType = ['student', 'military']

      const userStatusResponses = {}
      await Promise.all(identitiesType.map(async (type) => {
        const userStatusResp = await this.api.get(`/user/identity/${type}/status`);
        userStatusResponses[type] = userStatusResp['content']

        if (!userStatusResp || userStatusResp.code === 401) {
          this.props.dispatch(setReturnTo('/identity'));
          return this.setState({redirectTo: '/?logged_out=true'});
        }
      }))
      this.setState({
        loading: false,
        identities: userStatusResponses
      })

      // // bypass reading golf-id status..it may still 'pending' and with the old
      // // verificationId... force a restart so we can show the user our collect info form
      // if(this.state.userStatus === '_forceRestart') {
      //   this.setState({
      //     loading: false
      //   })
      //   return
      // }

      //
      // let userStatus = userStatusResp['content'];
      // console.log("X status", userStatus)

      // if (userStatus) {
      //   if (userStatus.status === 'pending') {
      //     // this.props.dispatch(setFlow({isDocUpload: true}));
      //     // setFlow({isDocUpload: true});
      //     if (userStatus.programId) {
      //       this.setState({
      //         verificationId: userStatus.verificationId,
      //         programId: userStatus.programId,
      //         userStatus: userStatus.status,
      //         loading: false
      //       })
      //     } else {
      //       this.setState({
      //         verificationId: userStatus.verificationId,
      //         userStatus: userStatus.status,
      //         loading: false
      //       })
      //     }
      //   }
      //   this.setState({
      //     loading: false,
      //     userStatus: userStatus.status,  // 'failed' or 'success'
      //     verificationId: userStatus.verificationId,
      //   })
      // }

      if (this.props.query.identity) {
        if (this.props.query.identity === 'military') {
          this.setScreen('military')
        }
        if (this.props.query.identity === 'student') {
          this.setScreen('student')
        }
      }

    } catch (e) {
      this.props.dispatch(setError({
          code: null,
          field: null,
          message: e.toString(),
          rawMessage: e,
          toast:false
        }
      ));
    }
  }

  setScreen = (value) => {
    this.setState({screen:value})
  }

  render() {

    if (this.state.redirectTo) {
      return <Redirect push to={this.state.redirectTo}/>;
    }

    let statusView =
      <div className={this.props.classes.pageContainer}>
        <Grid container spacing={0} className={this.props.classes.view} justify="center">
          {/*<PopAlert open={this.props.error.toast} message={this.props.error.message} onClose={this.onMessageClose}  hasExit={true}/>*/}

          <Grid item xs={12} md={6}>
            <LeftRail/>
          </Grid>

          <Grid item xs={12} md={6}>
          <PageHeader text="Identity Verifications" />
            <Card elevation={13} className={this.props.classes.card}>
              <CardContent>
                <Grid container spacing={5}>

                  <Grid item xs={12}>
                    <IdentityStatus label="Student" type="student" identities={this.state.identities} onClickScreen={this.setScreen}/>
                  </Grid>

                  <Grid item xs={12}>
                    <IdentityStatus label="Military" type="military" identities={this.state.identities} onClickScreen={this.setScreen}/>
                  </Grid>
                </Grid>

                {/*<Grid container spacing={10}>*/}
                {/*  <Grid item xs={12}>*/}
                {/*    <Button className={this.props.classes.buttonControls} color="primary" id="cancel"*/}
                {/*            onClick={this.goToBack} variant="contained">*/}
                {/*      Back */}
                {/*    </Button>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}

              </CardContent>
            </Card>
          </Grid>

        </Grid>
      </div>


    let view = statusView
    if (this.state.screen === 'student') {
      view = <StudentView query={this.props.query} ></StudentView>
    }

    if (this.state.screen === 'military') {
      view = <MilitaryView query={this.props.query} ></MilitaryView>
    }

    return (
        this.state.loading ? <Loading/> : view
    );
  }
}

const mapStateToProps = state => {
  return {
    flow: state.flow,
  };
}

const ConnectedPage = connect(mapStateToProps, null)(Page);

export default withStyles(styles)(ConnectedPage);
