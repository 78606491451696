import React from 'react';
import { Link } from 'react-router-dom';

import Api from '../../../../../../common/api';
import LogoutButton from '../../../../shared/components/logout-button';
import Heading from '../../../../shared/components/heading';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    text: {
        color: '#666',
        fontSize: '14px',
        margin: 0,
    },
    ctaBlock: {
        paddingTop: '40px'
    },
    link: {
        color: '#666',
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: '13px'
    },
    linkUnderline: {
        color: '#909090',
        borderBottom: 'solid 1px #909090',
        paddingBottom: '2px',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    list: {
        padding: 0,
        margin: 0
    },
    listItem: {
        marginBottom: '8px',
        listStyleType: 'none',
        color: '#666',
        fontSize: '14px'
    },
    listLabel: {
        color: '#666',
        fontSize: '14px',
        margin: '20px 0 8px 0',
    }
});

class GeneralControlsView extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            user: {},
            error: null
        };
        this.api = new Api();
    }

    async componentDidMount() {

        try {
            const result = await this.api.get('/user');
            if (result.code === 401) {
                return this.props.onLogout();
            }
            return this.setState({
                user: result.content
            });
        }
        catch (error) {
            return this.setState({ error });
        }
    }

    render() {

        return (
            <Grid container spacing={0} className={this.props.classes.view}>
                <Grid item xs={12}>
                    <Heading text="Log In Complete" />
                    <p className={this.props.classes.text}>Currently logged in as</p>
                    <p className={this.props.classes.text}><strong>{this.state.user.username}</strong></p>
                    <p className={this.props.classes.listLabel}>
                        <Link className={this.props.classes.link} to="/preferences"><strong>Click here</strong></Link> to</p>
                    <ul className={this.props.classes.list}>
                        <li className={this.props.classes.listItem}>- Change your password</li>
                        <li className={this.props.classes.listItem}>- Update Two-Factor Authentication Preferences</li>
                        <li className={this.props.classes.listItem}>- Manage Application Access</li>
                    </ul>
                    <div>
                        <LogoutButton onLogout={this.props.onLogout} />
                    </div>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(GeneralControlsView);
