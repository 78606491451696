import Crypto from 'crypto';
import React from 'react';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Delete from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import Auth from '../../../../../../common/auth';

import Heading from '../../../../shared/components/heading';

const md5 = (appName) => {

  return Crypto.createHash('md5').update(appName, 'utf-8').digest('hex');
};

const styles = theme => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  text: {
    fontSize: '14px'
  },
  textApproved: {
    fontSize: '12px'
  }
});

class View extends React.Component {

  constructor(props) {

    super(props);
    this.generateInit = this.generateInit.bind(this);
    this.onRevokeClick = this.onRevokeClick.bind(this);
  }

  generateInit(application) {
    return async function() {
      return this.onRevokeClick(application);
    }.bind(this);
  }

  onRevokeClick(application) {
    Auth.applicationRevoke(this.props.dispatch, application);
  }

  render() {

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Heading text='Application Access'/>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" className={this.props.classes.text}>These applications have been permitted access to your account information. You can remove an account's access by clicking the {<Delete style={{position: 'relative', top: '4px', width: '16px', height: '16px'}} />} button next to its name.</Typography>
            </Grid>
            <Grid item xs={12}>
              <div>
                <List>
                  {
                    this.props.user.grants && this.props.user.grants.length ?
                    this.props.user.grants.map((app) => {

                    const asDate = new Date(app.since);
                    const asPrintableDate = `${asDate.getMonth() + 1}-${asDate.getDate()}-${asDate.getFullYear()}`;

                    return (
                      <ListItem key={app.id}>
                        <ListItemAvatar>
                        {
                          app.appIconUrl ?
                          (<Avatar alt={app.name} src={app.appIconUrl} />) :
                          (<Avatar alt={app.name} src={`https://www.gravatar.com/avatar/${md5(app.name)}?d=identicon&f=y`} />)
                        }
                        </ListItemAvatar>
                        <ListItemText primary={
                            <div>
                            <div><strong>{app.name}</strong></div>
                            <div className={this.props.classes.textApproved}><em>Approved on {asPrintableDate}</em></div>
                            </div>
                        } />
                        <ListItemIcon>
                          <Typography color="textSecondary" className={this.props.classes.text}>
                            <Delete style={{ cursor: 'pointer', width: '16px', height: '16px' }} onClick={this.generateInit(app)} />
                          </Typography>
                        </ListItemIcon>
                        </ListItem>
                      )
                    })
                    :
                    <ListItem key="none-000">
                      <ListItemText primary="No approved applications." />
                    </ListItem>
                  }
                </List>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: Auth.userState(state)
  };
}

const ConnectedView = connect(mapStateToProps, null)(View);

export default withStyles(styles)(ConnectedView);
