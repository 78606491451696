import React, { Component } from 'react';
import { connect } from 'react-redux'

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Skins from './skins';
import { BrowserRouter } from 'react-router-dom';

import { applyTheme } from './actions/style';
import Api from './common/api'
import Auth from './common/auth'
import Local from './common/local'
import Util from './common/util'

class App extends Component {

  constructor(props) {
    super(props);

    this.api = new Api();

    this.applyTheme = this.applyTheme.bind(this);
    this.getMuiTheme = this.getMuiTheme.bind(this);
    this.selectSkin = this.selectSkin.bind(this);
  }

  async componentDidMount() {
    Auth.userValidate(this.props.dispatch);
  }

  async componentWillMount() {
    await Local.readStorage();
  }

  applyTheme(theme) {
    this.props.dispatch(applyTheme(theme));
  }

  getMuiTheme() {
    let theme = {...this.props.theme};
    theme.palette.type = this.props.mode;
    return createMuiTheme(theme);
  }

  selectSkin() {
    const params = Util.queryToObject(window.location.search);
    let skinName = window.globals ? window.globals.gidSkin : '';

    if (params.hasOwnProperty('skin')) {
      skinName = params.skin;
    }

    let skin = Skins[skinName];
    if (!skin) skin = Skins.base;
    return skin;
  }

  render() {
    const theme = this.getMuiTheme();
    const Skin = this.selectSkin();

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Skin
            applyTheme={this.applyTheme}
            loading={!this.props.user.hasValidated}
          />
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    mode: state.mode,
    user: Auth.userState(state),
    theme: state.theme
  }
}

export default connect(mapStateToProps, null)(App)
