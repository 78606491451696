import React, { Component }  from 'react';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import { toggleMode } from '../../../../actions/style';

const styles = (theme) => ({
  footer: {
    //bottom: 0,
    display: 'block',
    //left: 0,
    //position: 'fixed',
  },
  footerContainer: {
    padding: '10px 10px',
  },
  footerDivider: {
    height: '2px'
  },
  footerItem: {
    paddingRight: '25px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '10px'
    }
  },
  footerLink: {
    textDecoration: 'none',
  },
  footerModeToggleIcon: {
    cursor: 'pointer',
    width: '15px'
  },
  footerModeToggleContainer: {
    paddingTop: '8px'
  },
  footerStopHand: {
    height: '16px',
    marginRight: '4px',
    width: '16px'
  },
  footerText: {
    fontSize: '13px',
    fontWeight: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    },
  }
});

class Footer extends Component {
  constructor(props) {
    super(props);

    this.theme = 1;

    this.toggleDayNight = this.toggleDayNight.bind(this);
  }

  toggleDayNight() {
    const mode = this.props.mode === 'light' ? 'dark' : 'light';
    this.props.toggleMode(mode);
  }

  render() {
    const year = new Date().getFullYear();

    return (
      <footer className={this.props.classes.footer}>
        <Divider className={this.props.classes.footerDivider} color="primary" />
        <Grid container spacing={0} justify="center" className={this.props.classes.footerContainer}>
          <Grid item className={this.props.classes.footerItem}>
            <p className={this.props.classes.footerText}>
              COPYRIGHT &copy; {year} GOLF CHANNEL, ALL RIGHTS RESERVED.
            </p>
          </Grid>
          <Grid item className={this.props.classes.footerItem}>
            <p className={this.props.classes.footerText}>
              <Link color="inherit" href={this.props.privacyPolicyUrl}>PRIVACY POLICY - NEW</Link>
            </p>
          </Grid>
          <Grid item className={this.props.classes.footerItem}>
            <p className={this.props.classes.footerText}>
              <Link color="inherit" href={this.props.caNotice}>CA NOTICE</Link>
            </p>
          </Grid>
          <Grid item className={this.props.classes.footerItem}>
            <p className={this.props.classes.footerText}>
              <Link color="inherit" href={this.props.termsAndConditionsUrl}>TERMS &amp; CONDITIONS</Link>
            </p>
          </Grid>
          <Grid item className={this.props.classes.footerItem}>
            <p className={this.props.classes.footerText}>
              <Link color="inherit" href={this.props.privacyOptOut}>DO NOT SELL MY PERSONAL INFORMATION</Link>
            </p>
          </Grid>

        </Grid>
      </footer>
    );
  }
}

const mapStateToProps = state => {
  return {
    mode: state.mode
  }
}

const ConnectedFooter = connect(mapStateToProps, { toggleMode })(Footer);

export default withStyles(styles)(ConnectedFooter);
