import React from 'react';
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Api from '../../../../../common/api';
import Util from '../../../../../common/util';

import LeftRail from '../../../shared/components/left-rail';
import Link from '@material-ui/core/Link';
import PageHeader from '../../../shared/components/page-header';
import PopAlert from '../../../shared/components/pop-alert';
import StandardInput from '../../../shared/components/standard-input';

const styles = theme => ({
  button: {
    padding: "15px 20px",
    margin: "0 10px 10px 10px"
  },
  topButton: {
    marginBottom: '10px'
  },
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25px"
  },
  pageContainer: theme.pageContainer,
  noAccountLink: {
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
});

class ForgotPasswordApp extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      error: null,
      pop: false,
      username: '',
      sent: false,
      reSent: false,
      validation: {
        username: null
      },
      processing: false,
      redirect: null
    };
    this.api = new Api();
    this.generateOnInput = this.generateOnInput.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onCreateAccountClick = this.onCreateAccountClick.bind(this);
    this.onError = this.onError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitInternal = this.onSubmitInternal.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.onRequestClose = this.onRequestClose.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  async componentWillMount() {

    if (this.props.query.aslogin) {
      try {
        await this.onSubmitInternal(this.props.query.aslogin);
        return this.setState({
          username: this.props.query.aslogin,
          sent: true
        });
      }
      catch (e) {
        console.error(e);
        return;
      }
    }

    if (this.props.query.email) {
      this.setState({
        'username': this.props.query.email
      });
    }
  }

  generateOnInput(stateKey) {
    return function (e) {
      this.setState({
        [stateKey]: e.target.value,
        validation: { [stateKey]: null }
      });
    }.bind(this);
  }

  onCancel() {
    this.redirect(`/${Util.toQueryString(this.props.query)}`);
  }

  onCreateAccountClick(e) {
    e.preventDefault();
    this.redirect(`/new-account${Util.toQueryString(this.props.query)}`);
  }

  onError({ error }) {
    this.setState({
      error,
      pop: true
    });
  }

  onRequestClose() {
    this.setState({
      pop: false
    });
  }

  async onSubmit() {
    return await this.onSubmitInternal(this.state.username);
  }

  async onSubmitInternal(username) {
    const validation = {
            username: username.length ? null : 'Please enter an email address.'
    };
    const that = this;

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    try {
      const payload = { username };
      const set = this.props.query.set ? '?set=true' : '';

      if (this.props.query && Object.keys(this.props.query).length) {
        payload.parameters = Util.toCoreQueryObject(this.props.query) || {};
        payload.parameters.username = username;
      }

      this.setState({ processing: true });
      const response = await this.api.post(`/user/authenticate/forgot${set}`, payload);
      this.setState({ processing: false });

      if (response.code > 299) {
        if (response.code === 429) {
          return this.onError({
            error: `A ${this.props.query.set ? 'set password' : 'password reset'} email has recently been sent. Please wait a moment before trying to resend.`
                    });
                }

                if (response.code === 400) {
                    return this.onError({
                      error: function() {
                          if (response.content.validation && response.content.validation.keys.length && response.content.validation.keys[0] === 'username') {
                              validation.username = 'Please enter a valid email address.';
                              that.setState({ validation });
                              return 'This doesn\'t look like a valid email address. Please check it and try again.';
                          }
                          return undefined;
                      }() || 'Please double-check your email address for mistakes and try again.'
          });
        }

        return this.onError({
          error: 'We\'re sorry, our servers are having trouble at the moment. Please try again later.'
        });
      }

      return this.setState({
        username,
        sent: true,
        reSent: this.state.sent,
        validation: { username: null }
      });
    }
    catch (e) {
      this.setState({ processing: false });
      return this.onError({ error: e.toString() });
    }
  }

  async onSubmitPressed(e) {
    if (e.key === 'Enter') {
      return await this.onSubmit();
    }
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    let view;

    if (!this.state.sent) {
      view = (
        <Grid item xs={12} md={6}>
          <PageHeader text={this.props.query.set ? 'Set Password' : 'Forgot Password'} />
          <p>We will send you an email with a link to {this.props.query.set ? '' : 're'}set your password. Your {this.props.query.set ? 'set password' : 'reset'} link will expire in 24 hours.</p>
          <StandardInput
            inputType="email"
            style={{ marginBottom: '10px' }}
            error={this.state.validation.username}
            attribute="username"
            label="EMAIL ADDRESS"
            focus={true}
            value={this.props.query.email}
            onChange={this.generateOnInput('username')}
            onKeyUp={this.onSubmitPressed} />

          <Grid className={this.props.classes.buttonControls} container spacing={0}>
            <Button className={this.props.classes.topButton} color="primary" onClick={this.onSubmit} variant="contained">Send</Button>
            <Button className={this.props.classes.button} color="primary" onClick={this.onCancel} variant="outlined">Cancel</Button>
          </Grid>
        </Grid>
      );
    }
    else {
      view = (
        <Grid item xs={12} md={6}>
          <PageHeader text={this.props.query.set ? 'Set Password Sent' : 'Reset Sent'} />
          {
            this.props.query.aslogin ?
            (
              <div>
                <div><strong>Almost There!</strong></div>
                <p>
                  You’ll need to reset your password in order to complete Golf Account setup. An email has been {this.state.reSent ? 're-' : ''}sent to <strong>{this.props.query.aslogin}</strong> from GolfNow. Please open the link and complete the password reset now.
                </p>
                <p>
                  Remember, your reset link will expire in 24 hours.
                </p>
              </div>
            ) :
            (
              <div>
                <p>If there is an account associated with <strong>{this.state.username}</strong>, a {this.props.query.set ? 'set password' : 'password reset'}  email will be {this.state.reSent ? 're-' : ''}sent.</p>
                <p>Remember, your {this.props.query.set ? 'set password' : 'reset'} link will expire in 24 hours, emails may take up to 10 minutes to arrive, and be sure to check your Spam folder.</p>
                <p>
                  Didn't receive an email? Don't have an account? <Link className={this.props.classes.noAccountLink} onClick={this.onCreateAccountClick} href={`/new-account${Util.toQueryString(this.props.query)}`}>Click here to create one.</Link>
                </p>
              </div>
            )
          }
          <Grid className={this.props.classes.buttonControls} container spacing={0}>
            <Button className={this.props.classes.topButton} color="primary" onClick={this.onSubmit} variant="contained">Resend Email</Button>
            <Button className={this.props.classes.button} color="primary" onClick={this.onCancel} variant="outlined">Login</Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <div className={this.props.classes.pageContainer}>
        <Grid container spacing={0} className={this.props.classes.view}>
          <PopAlert open={this.state.pop} message={this.state.error} onClose={this.onRequestClose} />
          <Grid item xs={12} md={6}>
            <LeftRail />
          </Grid>
          {view}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ForgotPasswordApp);
