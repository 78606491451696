import React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import PeopleIcon from '@material-ui/icons/People';
import Typography from "@material-ui/core/Typography";

import { setUser} from '../../../../../../actions/auth';
import Api from '../../../../../../common/api';
import Local from '../../../../../../common/local';

import CurrentUser from '../../../../shared/components/current-user';
import LeftRail from '../../../../shared/components/left-rail';
import LogoutButton from '../../../../shared/components/logout-button';
import PageHeader from '../../../../shared/components/page-header';

const styles = (theme) => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  secondheadline: {
    fontSize: "28px",
    letterSpacing: "-1.3px",
    margin: "0 0 20px 0",
    fontWeight: "bold",
    [theme.breakpoints.up('md')]: {
      textAlign: "left",
      fontSize: "32px",
    },
  },
  buttonControls: {
    display: "flex",
    marginTop: "25px"
  },
  text: {
    fontSize: '14px',
  },
  textChangeUser: {
    fontSize: '16px',
  },
  ctaBlock: {
    paddingTop: '40px'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  linkUnderline: {
    color: '#909090',
    borderBottom: 'solid 1px #909090',
    paddingBottom: '2px',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  list: {
    paddingLeft: '18px'
  },
  listItem: {
    marginBottom: '8px',
    listStyleType: 'disc',
  },
  listLabel: {
    margin: '20px 0 8px 0',
  }
});

class GeneralControlsView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user: {},
      error: null
    };
    this.api = new Api();
    this.onPreferencesClick = this.onPreferencesClick.bind(this);
    this.onSwitchUsersClick = this.onSwitchUsersClick.bind(this);
    this.onVerifyIdentityClick = this.onVerifyIdentityClick.bind(this);
  }

  async componentDidMount() {

    try {
      const result = await this.api.get('/user');
      if (result.code === 401) {
        return this.props.onLogout();
      }

      this.props.dispatch(setUser(result.content));
      Local.setCurrentUsername(result.content.username);
      return this.setState({
        user: result.content
      });
    }
    catch (error) {
      return this.setState({ error });
    }
  }

  onVerifyIdentityClick(e) {
    e.preventDefault();
    this.setState({ redirect: '/identity' });
  }

  onPreferencesClick(e) {
    e.preventDefault();
    this.setState({ redirect: '/preferences' });
  }

  onSwitchUsersClick(e) {
    e.preventDefault();
    this.props.onShowAvailableUsers();
  }

  render() {

    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    return (
      <Grid container spacing={0} className={this.props.classes.view}>
        <Grid item xs={12} md={6}>
          <LeftRail />
        </Grid>
        <Grid item xs={12} md={6}>
          <PageHeader text="Log In Complete" />
          <CurrentUser user={this.state.user} />
          <Typography color="textSecondary" className={this.props.classes.textChangeUser}>
            <Link color="inherit" className={this.props.classes.link} onClick={this.onSwitchUsersClick}>
              {<PeopleIcon style={{position: 'relative', top: '4px', width: '18px', height: '18px'}} />} Switch User
            </Link>
          </Typography>

          {/*
          <Typography variant="inherit" color="textSecondary"><li className={this.props.classes.listItem}>
            <Link color="inherit" className={this.props.classes.link} onClick={this.onVerifyIdentityClick} href="/identity"><strong>[[WIP]] Verify Identity</strong></Link></li>
          </Typography>
          */}

          <Typography className={this.props.classes.listLabel}>
            <Link color="inherit" className={this.props.classes.link} onClick={this.onPreferencesClick} href="/preferences"><strong>Click here</strong></Link>
            <Typography variant="inherit" color="textSecondary"> to</Typography>
          </Typography>
          <ul className={this.props.classes.list}>
            <Typography variant="inherit" color="textSecondary"><li className={this.props.classes.listItem}>Change your password</li></Typography>
            <Typography variant="inherit" color="textSecondary"><li className={this.props.classes.listItem}>Update Two-Factor Authentication Preferences</li></Typography>
            <Typography variant="inherit" color="textSecondary"><li className={this.props.classes.listItem}>Manage Application Access</li></Typography>
          </ul>
            <div className={this.props.classes.buttonControls}>
            <LogoutButton onLogout={this.props.onLogout}/>
          </div>
        </Grid>
      </Grid>
    )
  }
}

const ConnectedGeneralControlsView = connect(null, null)(GeneralControlsView);

export default withStyles(styles)(ConnectedGeneralControlsView);
