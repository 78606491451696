import React from 'react';
import { Redirect, Link } from 'react-router-dom';

import Api from '../../../../../common/api';
import Local from '../../../../../common/local';
import Util from '../../../../../common/util';
import LeftRail from '../../../shared/components/left-rail';
import PageHeader from '../../../shared/components/page-header';
import StandardInput from '../../../shared/components/standard-input';
import PopAlert from '../../../shared/components/pop-alert';
import ResetPasswordRules from '../../../shared/components/reset-password-rules';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const styles = theme => ({
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    form: {
      padding: '0px 0 0 0',
    },
    buttonControls: {
      display: "flex",
      justifyContent: "center",
      marginTop: "25px"
    },
    button: {
      padding: "15px 20px",
      margin: "0 10px 10px 10px"
    },
    buttonWidthModification: {
      width: '150px'
    },
    pageContainer: theme.pageContainer,
    textInputContainer: {
      padding: "0 10px 0 0"
    },
    tooltipHeading: {
        fontSize: '15px',
        fontWeight: 'bold'
    },
    tooltipText: {
        fontSize: '13px'
    },
    createButton: {
      width: '200px',
      marginBottom: '10px',
      [theme.breakpoints.down('sm')]: {
          width: '150px',
      },
    }
});

class NewAccountApp extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            firstname: '',
            lastname: '',
            username: '',
            password: '',
            repeat: '',
            phonenumber: '',
            open: false,
            processing: false,
            showPassword: false,
            validation: {
                firstname: null,
                lastname: null,
                username: null,
                password: null,
                repeat: null,
                phonenumber: null
            }
        };

        this.api = new Api();
        this.generateOnInput = this.generateOnInput.bind(this);
        this.onError = this.onError.bind(this);
        this.onLogIntoAccount = this.onLogIntoAccount.bind(this);
        this.onPopClose = this.onPopClose.bind(this);
        this.onShowPassword = this.onShowPassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitPressed = this.onSubmitPressed.bind(this);
    }

    onPopClose() {

        this.setState({
            pop: false
        });
    }

    onError({ error }) {

        this.setState({
            error,
            pop: true,
            processing: false
        });
    }

    generateOnInput(stateKey) {

        return function (e) {

            this.setState({
                [stateKey]: e.target.value,
                validation: {
                    firstname: null,
                    lastname: null,
                    username: null,
                    password: null,
                    repeat: null,
                    phonenumber: null
                }
            });
        }.bind(this);
    }

    handleTooltipClose() {

        this.setState({ open: false });
    }

    handleTooltip() {

        const open = !this.state.open;
        this.setState({ open: open });
    }

    onShowPassword() {
      this.setState({showPassword: !this.state.showPassword});
    }

    onSubmitPressed(e) {

        if (e.key === 'Enter') {
            return this.onSubmit();
        }
    }

    async onSubmit() {

        const {
            firstname,
            lastname,
            username,
            password,
            repeat
        } = this.state;

        const validation = {
            firstname: firstname.length ? null : 'Please provide your first name.',
            lastname: lastname.length ? null : 'Please provide your last name/surname.',
            username: username.length ? null : 'Please provide an email address.',
            password: password.length ? null : 'Please enter a password.',
            repeat: repeat.length ? null : 'Please repeat the password you entered above.',
            phonenumber: null
        };

        if (password !== repeat) {
            validation.repeat = 'The passwords do not match. Please re-enter to try again.';
        }

        if (Object.values(validation).some((v) => { return v !== null; })) {
            return this.setState({ validation });
        }

        const payload = {
            firstName: firstname,
            lastName: lastname,
            username,
            password,
            twoFactor: {
              enabled: false
            }
        };

        this.setState({ processing: true });

        const that = this;

        try {
            const result = await this.api.post(`/user${this.props.query.client_id ? `?clientId=${this.props.query.client_id}` : ''}`, payload);

            if (result.code !== 200) {
                if (result.code === 412) {
                    return this.setState({
                        processing: false,
                        validation: {
                            firstname: this.state.validation.firstname ? this.state.validation.firstname.slice() : null,
                            lastname: this.state.validation.lastname ? this.state.validation.lastname.slice() : null,
                            username: (
                                <span>
                                    Error: Sorry, that email address is already registered. <Link onClick={this.onLogIntoAccount} to="/">Login instead?</Link>
                                </span>
                            ),
                            password: this.state.validation.password ? this.state.validation.password.slice() : null,
                            repeat: this.state.validation.repeat ? this.state.validation.repeat.slice() : null,
                            phonenumber: this.state.validation.phonenumber ? this.state.validation.phonenumber.slice() : null
                        }
                    });
                }
                else if (result.code === 400) {
                    this.setState({ processing: false });
                    return this.onError({
                        error: function() {
                            if ((result.content.validation &&
                                 result.content.validation.keys.length &&
                                 result.content.validation.keys[0] === 'username') ||
                                 (result.content && result.content.message === 'The email address is not valid.')
                               ) {
                                validation.username = 'Please enter a valid email address.';
                                that.setState({ validation });
                                return 'This doesn\'t look like a valid email address. Please check it and try again.';
                            }

                            if (result.content.message) {
                                return result.content.message;
                            }
                            return undefined;
                        }() || 'Please double-check your email address for mistakes and try again.'
                    });
                }
                else {
                    this.setState({ processing: false });
                    return this.onError({
                        error: `Sorry, an unknown error occurred. Please try again shortly or contact customer support for immediate assistance.`
                    });
                }
            }

            Local.userLogin(result.content.token, username);
            return this.setState({ redirect: `/${Util.toQueryString(this.props.query, ['reauthenticate'])}`, processing: false });
        }
        catch (e) {
            return this.onError({ error: e.toString() });
        }
    }

    onLogIntoAccount(e) {
        e.preventDefault();
        return this.setState({
            redirect: `/${Util.toQueryString(this.props.query)}`
        });
    }

    componentWillMount() {

        if (this.props.query.aslogin) {
            return this.setState({
                username: this.props.query.aslogin
            });
        }
    }

    render() {

        if (this.state.redirect) {
            return (
                <Redirect to={this.state.redirect} />
            );
        }

        const endAdornment = this.state.showPassword ?
          <VisibilityOffIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
          <VisibilityIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

        return (
          <div className={this.props.classes.pageContainer}>
            <Grid container spacing={0} className={this.props.classes.view} >
                <PopAlert
                    open={this.state.pop}
                    message={this.state.error}
                    onClose={this.onPopClose}
                    hasExit={true}
                />
                <Grid item xs={12} md={6}>
                  <LeftRail />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PageHeader text="Create Account" />
                    <Grid container spacing={2} className={this.props.classes.form}>
                        <Grid item xs={6} className={this.props.classes.textInputContainer}>
                            <StandardInput
                                style={{ marginBottom: '2rem' }}
                                error={this.state.validation.firstname}
                                focus={true}
                                label="FIRST NAME"
                                attribute="firstname"
                                onChange={this.generateOnInput('firstname')}
                                onKeyUp={this.onSubmitPressed} />
                        </Grid>
                        <Grid item xs={6} className={this.props.classes.textInputContainer}>
                            <StandardInput
                                style={{ marginBottom: '2rem' }}
                                error={this.state.validation.lastname}
                                label="LAST NAME"
                                attribute="lastname"
                                onChange={this.generateOnInput('lastname')}
                                onKeyUp={this.onSubmitPressed} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={this.props.classes.textInputContainer}>
                        <StandardInput
                            inputType="email"
                            value={this.state.username}
                            disabled={!!this.props.query.aslogin}
                            style={{ marginBottom: '2rem' }}
                            error={this.state.validation.username}
                            label="EMAIL ADDRESS"
                            attribute="username"
                            onChange={this.generateOnInput('username')}
                            onKeyUp={this.onSubmitPressed} />
                    </Grid>
                    <ResetPasswordRules
                      password={this.state.password}
                      passwordRepeat={this.state.repeat}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6} className={this.props.classes.textInputContainer}>
                            <StandardInput
                                inputType={this.state.showPassword ? 'text' : 'password'}
                                style={{ marginBottom: '10px' }}
                                error={this.state.validation.password}
                                password={true}
                                label="PASSWORD"
                                attribute="password"
                                endAdornment={endAdornment}
                                onChange={this.generateOnInput('password')}
                                onKeyUp={this.onSubmitPressed} />
                        </Grid>
                        <Grid item xs={6} className={this.props.classes.textInputContainer}>
                            <StandardInput
                                inputType={this.state.showPassword ? 'text' : 'password'}
                                style={{ marginBottom: '10px' }}
                                error={this.state.validation.repeat}
                                password={true}
                                label="REPEAT PASSWORD"
                                attribute="repeat"
                                onChange={this.generateOnInput('repeat')}
                                onKeyUp={this.onSubmitPressed} />
                        </Grid>
                    </Grid>

                    <Grid className={this.props.classes.buttonControls} container spacing={0}>
                      <Button className={this.props.classes.button} color="primary" onClick={this.onSubmit} variant="contained">
                          {this.state.processing ?
                              (
                                  <CircularProgress size={14} color="inherit" />
                              )
                           : 'Create Account'
                          }
                      </Button>
                      <Button className={`${this.props.classes.button} ${this.props.classes.buttonWidthModification}`} color="primary" onClick={this.onLogIntoAccount} variant="outlined">
                          Log into Account
                      </Button>
                    </Grid>
                </Grid>
            </Grid>
          </div>
        );
    }
}

export default withStyles(styles)(NewAccountApp);
