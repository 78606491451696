import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { setError } from '../../../../../../actions/auth';

import Auth from '../../../../../../common/auth';
import Heading from '../../../../shared/components/heading';
import ResetPasswordRules from '../../../../../golfid/shared/components/reset-password-rules';
import StandardInput from '../../../../shared/components/standard-input';
import Util from '../../../../../../common/util';


const styles = theme => ({
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    form: {
      padding: '0px 0 0 0',
    },
    buttonControls: {
      display: "flex",
      justifyContent: "center",
      marginTop: "25px"
    },
    button: {

    },
    buttonWidthModification: {
      width: '180px'
    },
    pageContainer: theme.pageContainer,
    tooltipHeading: {
        fontSize: '15px',
        fontWeight: 'bold'
    },
    tooltipText: {
        fontSize: '13px'
    },
    createButton: {
      width: '200px',
      marginBottom: '10px',
      [theme.breakpoints.down('sm')]: {
          width: '150px',
      },
    }
});

class View extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      firstname: '',
      lastname: '',
      username: '',
      password: '',
      repeat: '',
      phonenumber: '',
      open: false,
      processing: false,
      showPassword: false,
      validation: {
        firstname: null,
        lastname: null,
        username: null,
        password: null,
        repeat: null,
        phonenumber: null
      }
    };

    this.generateOnInput = this.generateOnInput.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onLogIntoAccount = this.onLogIntoAccount.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { username: null }
      });

      if (this.props.error.field) {
        this.props.dispatch(setError({}));
      }
    }.bind(this);
  }

  onCancel() {
    this.redirect(`/${Util.toQueryString(this.props.query)}`);
  }

  onLogIntoAccount(e) {
      this.redirect(`/${Util.toQueryString(this.props.query)}`);
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      return await this.onSubmit();
    }
  }

  onShowPassword() {
    this.setState({showPassword: !this.state.showPassword});
  }

  async onSubmit() {
    const {
      firstname,
      lastname,
      username,
      password,
      repeat
    } = this.state;

    const validation = {
      firstname: firstname.length ? null : 'Please provide your first name.',
      lastname: lastname.length ? null : 'Please provide your last name/surname.',
      username: username.length ? null : 'Please provide an email address.',
      password: password.length ? null : 'Please enter a password.',
      repeat: repeat.length ? null : 'Please repeat the password you entered above.',
      phonenumber: null
    };

    if (password !== repeat) {
      validation.repeat = 'The passwords do not match. Please re-enter to try again.';
    }

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    Auth.createUser(this.props.dispatch, firstname, lastname, username, password);
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    let firstnameErr = this.state.validation.firstname || null;
    let lastnameErr = this.state.validation.lastname || null;
    let usernameErr = this.state.validation.username || null;
    let passwordErr = this.state.validation.password || null;
    let repeatErr = this.state.validation.repeat || null;

    if (this.props.flow.redirect || this.state.redirect) {
      const redirect = this.props.flow.redirect || this.state.redirect;
      return <Redirect push to={redirect} />;
    }

    if (this.props.error) {
      if (this.props.error.field === 'username') {
        usernameErr = this.props.error.message;
      } else if (this.props.error.field === 'password') {
        passwordErr = this.props.error.message;
      }
    }

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    return (
      <Card elevation={3} className={this.props.classes.view}>
        <CardContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Heading text="Create Account" />
            </Grid>
            <Grid item xs={6}>
              <StandardInput
                  style={{ width: '100%' }}
                  error={firstnameErr}
                  focus={true}
                  label="FIRST NAME"
                  labelWidth={90}
                  attribute="firstname"
                  onChange={this.generateOnInput('firstname')}
                  onKeyUp={this.onSubmitPressed} />
            </Grid>
            <Grid item xs={6}>
              <StandardInput
                  style={{ width: '100%' }}
                  error={lastnameErr}
                  label="LAST NAME"
                  labelWidth={90}
                  attribute="lastname"
                  onChange={this.generateOnInput('lastname')}
                  onKeyUp={this.onSubmitPressed} />
            </Grid>
            <Grid item xs={12}>
              <StandardInput
                  inputType="email"
                  value={this.state.username}
                  disabled={!!this.props.query.aslogin}
                  style={{ width: '100%' }}
                  error={usernameErr}
                  label="EMAIL ADDRESS"
                  labelWidth={120}
                  attribute="username"
                  onChange={this.generateOnInput('username')}
                  onKeyUp={this.onSubmitPressed} />
            </Grid>
            <Grid item>
              <ResetPasswordRules
                password={this.state.password}
                passwordRepeat={this.state.repeat}
              />
            </Grid>
            <Grid item xs={6}>
              <StandardInput
                  inputType={this.state.showPassword ? 'text' : 'password'}
                  style={{ width: '100%' }}
                  error={passwordErr}
                  password={true}
                  label="PASSWORD"
                  labelWidth={80}
                  attribute="password"
                  endAdornment={endAdornment}
                  onChange={this.generateOnInput('password')}
                  onKeyUp={this.onSubmitPressed} />
            </Grid>
            <Grid item xs={6}>
              <StandardInput
                  inputType={this.state.showPassword ? 'text' : 'password'}
                  style={{ width: '100%' }}
                  error={repeatErr}
                  password={true}
                  label="REPEAT PASSWORD"
                  labelWidth={140}
                  attribute="repeat"
                  onChange={this.generateOnInput('repeat')}
                  onKeyUp={this.onSubmitPressed} />
            </Grid>
            <Grid item xs={12}>
              <Button className={this.props.classes.button} color="primary" onClick={this.onSubmit} variant="contained">
                {this.state.processing ?
                    (
                        <CircularProgress size={14} color="inherit" />
                    )
                 : 'Create Account'
                }
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button className={`${this.props.classes.button} ${this.props.classes.buttonWidthModification}`} color="secondary" onClick={this.onLogIntoAccount} variant="contained">
                  Log into Account
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    flow: state.flow
  };
}

const ConnectedView = connect(mapStateToProps)(View);

export default withStyles(styles)(ConnectedView);
