import React from 'react';

import Api from '../../../../../../common/api';
import Local from '../../../../../../common/local';

import StandardInput from '../../../../shared/components/standard-input';
import Heading from '../../../../shared/components/heading';
import ResetPasswordRules from '../../../../shared/components/reset-password-rules';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    view: {
      ...theme.internalContainer,
      [theme.breakpoints.up('md')]: {
        padding: theme.internalContainer._themeMdPadding
      },
    },
    ctaBlock: {
        paddingTop: '40px'
    },
    link: {
        color: '#909090',
        textDecoration: 'none',
        cursor: 'pointer',
        fontSize: '13px'
    },
    linkUnderline: {
        color: '#909090',
        borderBottom: 'solid 1px #909090',
        paddingBottom: '2px',
        textDecoration: 'none',
        cursor: 'pointer',
        marginBottom: '2px'
    },
    text: {
        color: '#666',
        fontSize: '14px'
    }
});

class UpgradeView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: this.props.username,
            password: '',
            repeat: '',
            processing: false,
            validation: {
                password: null,
                repeat: null
            }
        };
        this.api = new Api();
        this.onSubmit = this.onSubmit.bind(this);
        this.generateOnInput = this.generateOnInput.bind(this);
        this.onSubmitPressed = this.onSubmitPressed.bind(this);
    }

    generateOnInput(stateKey) {

        return function (e) {

            this.setState({
                [stateKey]: e.target.value,
                validation: { password: null, repeat: null }
            });
        }.bind(this);
    }

    onSubmitPressed(e) {

        if (e.key === 'Enter') {
            return this.onSubmit();
        }
    }

    async onSubmit() {

        const {
            password,
            repeat
        } = this.state;

        const validation = {
            password: password.length ? null : 'Error: Please provide a password.',
            repeat: repeat.length ? null : 'Error: Please repeat your password.'
        };

        if (password !== repeat) {
            validation.repeat = 'Error: Your passwords must match. Please try typing your password again.';
        }

        if (Object.values(validation).some((v) => { return v !== null; })) {
            return this.setState({ validation });
        }

        this.setState({ processing: true });
        try {
            const uri = `/user${this.props.query.client_id ? `?clientId=${this.props.query.client_id}` : ''}`;
            const response = await this.api.post(uri, { username: this.state.username, password });

            if (response.code === 400) {
                this.setState({ processing: false });
                return this.props.onError({ error: response.content.message });
            }
        }

        catch (e) {
            console.error(e);
            this.setState({ processing: false });
            return this.props.onError({ error: e.toString() });
        }

        try {
            const response = await this.api.post('/user/authenticate', { username: this.state.username, password });
            Local.userLogin(response.content.token, this.state.username);
            this.setState({ processing: false });
            return this.props.onLoginSuccess();
        }
        catch (e) {
            console.error(e);
            this.setState({ processing: false });
            return this.props.onError({ error: e.toString() });
        }
    }

    render() {

        return (
            <Grid container spacing={0} className={this.props.classes.view}>
                <Grid item xs={12}>
                    <Heading text="Upgrade Your Account" />
                    <p className={this.props.classes.text}>We noticed that you haven't created an account with us!</p>
                    <ResetPasswordRules textClass={this.props.classes.text} />
                </Grid>
                <Grid item xs={12}>
                    <StandardInput
                        style={{ marginBottom: '2rem' }}
                        error={this.state.validation.password}
                        password={true}
                        attribute="password"
                        label="PASSWORD"
                        onChange={this.generateOnInput('password')}
                        onKeyUp={this.onSubmitPressed} />
                </Grid>
                <Grid item xs={12}>
                    <StandardInput
                        style={{ marginBottom: '2rem' }}
                        error={this.state.validation.repeat}
                        password={true}
                        attribute="repeat"
                        label="REPEAT PASSWORD"
                        onChange={this.generateOnInput('repeat')}
                        onKeyUp={this.onSubmitPressed} />
                </Grid>
                <Grid item xs={12}>
                    <Button onClick={this.onSubmit}>
                        {this.state.processing ?
                            (<CircularProgress size={14} color="inherit" />)
                            : 'Create Account'
                        }
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(UpgradeView);
